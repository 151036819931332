import React, { useState } from 'react';
import BrochureDialog from '../FormDialog/FormDialog';
import './About.css';

const About = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const openDialog = (e) => {
    e.preventDefault();
    setIsDialogOpen(true); // Always set to true to ensure the dialog opens
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <section className="overview-section">
      <div className="overview-container">
        <div className="overview-content">
          <h2>Codename Isle Of Breeze Seawoods</h2>
          <p>
            At Palm Beach Road, Seawoods Nerul Navi Mumbai
            <br />
            <strong>GAMI CODENAME ISLE OF BREEZE SEAWOODS</strong>
          </p>
          <p className={`overview-text ${isExpanded ? "expanded" : "collapsed"}`}>
            Gami NMS Codename Isle of Breeze New Launch Seawood in Navi Mumbai is an exceptional residential development by Gami Group Properties. Offering a variety of apartment options, including 2 BHK, 3 BHK, 4 BHK, and 5 BHK units, this project caters to diverse housing needs. As an upcoming project in the vibrant area of Seawood, Navi Mumbai, Gami NMS Codename Isle of Breeze promises modern amenities and a prime location, making it an ideal choice for homebuyers seeking comfort and convenience in Mumbai's residential landscape.
          </p>
          <div className="button-group">
            <button className="show-more-button" onClick={toggleExpansion}>
              {isExpanded ? "Show less" : "Show more"}
            </button>
            <button className="download-brochure-button" onClick={openDialog}>
              <i className="fas fa-download"></i> Download Brochure
            </button>
          </div>

          {/* Project Highlights Section */}
          <div className='master-highlights-section'>
          <h1>About Gami Group</h1>
          <p>Born with a philosophy to create lifestyles that are imagined, desired and wished for; Gami Group was established in the year 2000. Within a short span the company went on to capture the imagination of customers and leap frogged to immeasurable heights of success. Acknowledgment from the real estate fraternity and patronage of our customers stands testimony to this.</p>
          <div className="highlights-section">
            <h3>Project Highlights</h3>
            <ul>
              <li>✓ 43+ exquisite amenities</li>
              <li>✓ Wellness lawn and walkway loop for a healthy lifestyle</li>
              <li>✓ Recreational facilities like tri-cycle track, swing deck, leisure deck, and tree house</li>
              <li>✓ Separate gym for women</li>
              <li>✓ Music library and art gallery</li>
              <li>✓ Located 20 minutes from Navi Mumbai International Airport</li>
              <li>✓ MAHARERA ID: P51700052377</li>
            </ul>
          </div>
          </div>
        </div>
      </div>
      <BrochureDialog isOpen={isDialogOpen} closeDialog={closeDialog} />
    </section>
  );
};

export default About;
