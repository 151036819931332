import React, { useEffect, useRef } from 'react';
import About from '../About/About';
import BottomNav from '../Bottomav/BottomNav';
import CustomCarousel from '../Carousel/Carousel';
import FAQ from '../FAQ/FAQ';
import FloorPlan from '../FloorPlan/FloorPlan';
import Footer from '../Footer/Footer';
import MyGallery from '../Gallery/Gallery';
import Header from '../Header/Header';
import LeftSection from '../LeftSection/LeftSection';
import MapView from '../MapView/MapView';
import PricingSection from '../PricingSection/PricingSection';
import PreRegisterForm from '../TopButtons/PreRegisterForm';
import VirtualSiteTour from '../VirtualSiteTour/VirtualSiteTour';
import './MainLayout.css';

const MainLayout = () => {
    const homeRef = useRef(null);
    const priceRef = useRef(null);
    const sitePlanRef = useRef(null);
    const amenitiesRef = useRef(null);
    const galleryRef = useRef(null);
    const locationRef = useRef(null);
    const brochureRef = useRef(null);

    const handleNavClick = (section) => {
      switch (section) {
        case 'Home':
          homeRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'Price':
          priceRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'Site & Floor Plan':
          sitePlanRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'Amenities':
          amenitiesRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'Gallery':
          galleryRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'Location':
          locationRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'Brochure':
          brochureRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        default:
          break;
      }
    };

    const items = [
      { image: 'path/to/image1.jpg', title: 'Slide 1', description: 'Description for slide 1' },
      { image: 'path/to/image2.jpg', title: 'Slide 2', description: 'Description for slide 2' },
      { image: 'path/to/image3.jpg', title: 'Slide 3', description: 'Description for slide 3' },
    ];



    const handleScroll = () => {
      const sections = document.querySelectorAll('.section');
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          section.classList.add('in-view');
        } else {
          section.classList.remove('in-view');
        }
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
      <div className="main-layout">
        <Header onNavClick={handleNavClick} />

        
        <div className="left-section">
        <LeftSection/>
        </div>


        <div className="right-section">
          <div ref={homeRef} id="home" className="section">
           <CustomCarousel/>
           <div id="pre-register-form">
        <PreRegisterForm />
      </div>
           <About/>
          </div>
          <div ref={priceRef} id="price" className="section">
           <PricingSection />
          </div>
          <div ref={sitePlanRef} id="site-floor-plan" className="section">
            <FloorPlan/>
          </div>
          <div ref={amenitiesRef} id="amenities" className="section">
            {/* <AmenitiesCarousel/> */}
          </div>
          <div ref={galleryRef} id="gallery" className="section">
            <MyGallery/>
          </div>
          <div ref={locationRef} id="location" className="section">
          <MapView/>
          </div>
          <div ref={brochureRef} id="brochure" className="section">
            <VirtualSiteTour/>
            <FAQ/>
          </div>

          <div ref={brochureRef} id="brochure" className="section">
          <Footer/>
          </div>
        </div>
        <div className="bottom-nav-container">
        <BottomNav />
      </div>
      </div>
    );
};

export default MainLayout;
