import React from 'react';
import FloorPlanLayout from '../customcomponent/FloorPlanLayout';
import MasterPlanLayout from '../customcomponent/MasterPlanLayout';
import floorplanimg from '../images/gamilogo.png'; // Replace with the correct path to your image file
import './FloorPlan.css'; // Import your custom CSS

function FloorPlan() {
  return (
    <div>
      <div className="title-container">
        <h2>Master Plan</h2>
      </div>
      <div className="master-plan-container">
        <MasterPlanLayout imageSrc={floorplanimg}/>
      </div>
      
      <div className="title-container">
        <h2>Floor Plans</h2>
      </div>
      <div className="floor-plan-card-container">
        <FloorPlanLayout title={"Floor Plan 1"} imageSrc={floorplanimg}/>
        <FloorPlanLayout title={"Floor Plan 2"} imageSrc={floorplanimg}/>
        <FloorPlanLayout title={"Floor Plan 3"} imageSrc={floorplanimg}/>
      </div>
    </div>
  );
}

export default FloorPlan;
