import React from 'react';
import { FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h3>About Us</h3>
          <p>
            We are a leading real estate company in Navi Mumbai, committed to providing the best commercial and residential spaces with a focus on quality and innovation.
          </p>
        </div>
        <div className="footer-section links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#privacy-policy">Privacy policy</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h3>Contact Us</h3>
          <p><FaPhone className="footer-icon" /> +91 12345 67890</p>
          <p><FaWhatsapp className="footer-icon" /> +91 98765 43210</p>
          <p><FaEnvelope className="footer-icon" /> info@example.com</p>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; 2024 Your Company Name | All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
