// components/LeftSection.js
import React from 'react';
import FooterIcons from '../TopButtons/FooterIcons';
import PreRegisterForm from '../TopButtons/PreRegisterForm';
import SiteVisitInfo from '../TopButtons/SiteVisitInfo';
import SocialShare from '../TopButtons/SocialShare';
import TopButtons from '../TopButtons/TopButtons';
import './LeftSection.css';

function LeftSection() {
  return (
    <div className="left-sec">
      <TopButtons />
      <PreRegisterForm />
      <SiteVisitInfo />
      <SocialShare />
      <FooterIcons />
  
    </div>
  );
}

export default LeftSection;
