import React from 'react';
import './PreRegisterForm.css'; // Add form styles

const PreRegisterForm = () => {
  return (
    <div className="pre-register-form">
      <h3>Pre-Register here for Best Offers</h3>
      <form>
        <input type="text" placeholder="Name" />
        <input type="tel" placeholder="Mobile No" />
        <input type="email" placeholder="E-Mail Address" />
        <button type="submit">Pre-Register Now</button>
      </form>
      <p>Call Us : <a href="tel:+919137458691">+91 9137458691</a></p>
    </div>
  );
};

export default PreRegisterForm;
