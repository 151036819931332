import React from 'react';
import FloorPlanLayout from '../customcomponent/FloorPlanLayout';
import floorimg from '../images/gamilogo.png';
import './MapView.css'; // Create this CSS file for styles

const MapView = () => {
  return (
    <div className="map-view-container">
      <div className="map-section">
        <h2>Map View</h2>
        <div className="map">
          {/* Replace with your Google Map component */}
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.6546546315827!2d73.0251727755224!3d19.02417803412959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3d5b2456c03%3A0x38eb19f96b7a4e05!2sSector%2046%2C%20Seawoods%2C%20Navi%20Mumbai%2C%20Maharashtra%20400706!5e0!3m2!1sen!2sin!4v1616159517478!5m2!1sen!2sin"
            width="100%"
            height="350"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div className="map-section">
        <h2>Location Map</h2>
        <div className="location-map">
         <FloorPlanLayout title={"Location Map"} imageSrc={floorimg}/>
        </div>
      </div>
    </div>
  );
};

export default MapView;
