import React from 'react';
import affordableprice from '../images/affordable.png';
import callicon from '../images/customer-service.png';
import sitevisit from '../images/protection.png';
import './FormDialog.css';

function BrochureDialog({ isOpen, closeDialog }) {
  if (!isOpen) return null;

  return (
    <div className="brochure-dialog-overlay">
      <div className="brochure-dialog">
        <button className="close-button" onClick={closeDialog}>×</button>
        <h2 className="brochure-dialog-title">Download Brochure</h2>
        <div className="brochure-dialog-content">
          <div className="promise-section">
            <div className="promise-item">
              <img src={callicon} alt="Instant Call Back" />
              <p>Instant Call Back</p>
            </div>
            <div className="promise-item">
              <img src={sitevisit} alt="Free Site Visit" />
              <p>Free Site Visit</p>
            </div>
            <div className="promise-item">
              <img src={affordableprice} alt="Unmatched Price" />
              <p>Unmatched Price</p>
            </div>
          </div>
          <form className="brochure-form">
            <h3>Register Here And Avail The <span className="best-offers">Best Offers!!</span></h3>
            <input type="text" placeholder="Name" />
            <input type="text" placeholder="Mobile No" />
            <input type="email" placeholder="E-Mail Address" />
            <button type="submit">Download now</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BrochureDialog;
