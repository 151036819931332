// src/FAQ.js
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {

    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };


  const faqs = [
    {
      question: "What is the pricing of Codename Isle of Breeze Seawoods?",
      answer: "The price of Codename Isle of Breeze Seawoods starts at 2.25 Cr.* Onwards.",
    },
    {
      question: "Will/Is Codename Isle of Breeze Seawoods RERA registered?",
      answer: "Yes, Codename Isle of Breeze Seawoods will be / is RERA approved project.",
    },
    {
      question: "What is the location of Codename Isle of Breeze Seawoods?",
      answer: "The Codename Isle of Breeze Seawoods is located at Palm Beach Road, Seawoods Nerul Navi Mumbai.",
    },
    {
      question: "What type of room configurations are available in the project?",
      answer: "The Codename Isle of Breeze Seawoods is available in 2, 3, 4, 5 BHK configurations.",
    },
    {
      question: "Why Minespace for Property Buying?",
      answer: "The property market of Mumbai & Navi Mumbai offers property for sale in new residential projects in high-rise towers in all budgets whether Pre-Launch Projects, under construction, ready to move in (RTMI) or nearing possession. Whether it is for self-use or investment property requirements, we strive hard to provide the best offers on the best properties available.",
    },
  ];

  return (
    <div className="faq-container">
      <div className="faq-section">
        <h2>FAQs - of Codename Isle of Breeze Seawoods</h2>
        <div className="faq-label2">FAQ</div>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <FontAwesomeIcon
               icon={openIndex === index ? faChevronUp : faChevronDown}
               className="faq-icon" />
              {faq.question}
            </div>
            <div className="faq-answer">
              <strong>Ans: </strong>{faq.answer}
            </div>
          </div>
        ))}
      </div>
      <div className="faq-label">FAQ</div>
    </div>
  );
};

export default FAQ;
