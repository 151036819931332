import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import imgcarosel1 from '../images/img1.webp';
import imgcarose2 from '../images/img2.webp';
import imgcarosel5 from '../images/img5.webp';
// import imgcarose3 from '../images/img3.webp';
// import imgcarose4 from '../images/img4.webp'; // If you don't need this, ensure it's not referenced later.
import './Carousel.css';

const CustomCarousel = () => {
  return (
    <Carousel 
      autoPlay 
      infiniteLoop 
      showThumbs={false} 
      showStatus={false} 
      interval={3000}
      transitionTime={1000}
      className="custom-carousel"
    >
      <div>
        <img 
          src={imgcarosel1} 
          alt="Slide 1" 
          loading="lazy" 
        />
      </div>
      <div>
        <img 
          src={imgcarose2} 
          alt="Slide 2" 
          loading="lazy" 
        />
      </div>
      <div>
        <img 
          src={imgcarosel1} 
          alt="Slide 3" 
          loading="lazy" 
        />
      </div>
      <div>
        <img 
          src={imgcarosel5}  // Using the same image as Slide 1
          alt="Slide 4" 
          loading="lazy" 
        />
      </div>
    </Carousel>
  );
};

export default CustomCarousel;
