import { CButton, CCard, CCardImage } from '@coreui/react';
import React, { useState } from 'react';
import BrochureDialog from '../FormDialog/FormDialog';
import './MasterPlanLayout.css'; // Import your custom CSS

 function MasterPlanLayout({imageSrc}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = (e) => {
    e.preventDefault();
    setIsDialogOpen(true); // Always set to true to ensure the dialog opens
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

    return (
        <div className="master-paln-container">
          <CCard className="master-paln-card">
            <div className="card-overlay">
              <CButton className="enquire-button" color="primary" onClick={openDialog}>ENQUIRE NOW</CButton>
            </div>
            <CCardImage className="card-image" orientation="top" src={imageSrc} />
          </CCard>
          <BrochureDialog isOpen={isDialogOpen} closeDialog={closeDialog} />
        </div>
      );
    }

export default MasterPlanLayout;
