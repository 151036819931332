import React from 'react';
import './TopButtons.css'; // Add styles for buttons

const TopButtons = () => {
  return (
    <div className="top-buttons">
      <button className="visit-btn">Organize Site Visit</button>
      <button className="whatsapp-btn">Send us Whatsapp</button>
      <button className="callback-btn">Request Call Back</button>
    </div>
  );
};

export default TopButtons;
