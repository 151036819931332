import React from 'react';
import './SocialShare.css'; // Add styles for social share

const SocialShare = () => {
  return (
    <div className="social-share">
      <p>Share This Project On Social Media</p>
      <div className="social-icons">
        <a href="#linkedin"><i className="fab fa-linkedin"></i></a>
        <a href="#twitter"><i className="fab fa-twitter"></i></a>
        <a href="#whatsapp"><i className="fab fa-whatsapp"></i></a>
      </div>
    </div>
  );
};

export default SocialShare;
