import React from 'react';
import './FooterIcons.css'; // Add styles for footer icons

const FooterIcons = () => {
  return (
    <div className="footer-icons">
      <i className="fas fa-phone"></i>
      <i className="fas fa-percentage"></i>
      <i className="fas fa-car"></i>
      <i className="fas fa-map-marker-alt"></i>
    </div>
  );
};

export default FooterIcons;
