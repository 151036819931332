import React, { useState } from 'react';
import FloorPlanLayout from '../customcomponent/FloorPlanLayout';
import BrochureDialog from '../FormDialog/FormDialog';
import floorplanimg from '../images/gamilogo.png';
import './PricingSection.css';

const PricingSection = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const pricingData = [
        { type: '2 BHK', area: 'On Request sq.ft.', price: '₹On Request' },
        { type: '3 BHK', area: 'On Request sq.ft.', price: '₹On Request' },
        { type: '4 BHK', area: 'On Request sq.ft.', price: '₹On Request' },
        { type: '5 BHK', area: 'On Request sq.ft.', price: '₹On Request' }
    ];

    const openDialog = (e) => {
      e.preventDefault();
      setIsDialogOpen(true); // Always set to true to ensure the dialog opens
    };
  
    const closeDialog = () => {
      setIsDialogOpen(false);
    };

    return (
        <div className="pricing-enquiry-container">
            <div className="pricing-section">
                <div className="pricing-header">
                    <h2 style={{textAlign:'left'}}>Price</h2>
                </div>
                <div className="pricing-table">
                    {pricingData.map((item, index) => (
                        <div className="pricing-row" key={index}>
                            <div className="pricing-type">{item.type}</div>
                            <div className="pricing-area">{item.area}</div>
                            <div className="pricing-price">{item.price}</div>
                            <div className="pricing-action">
                                <button className="action-button" onClick={openDialog}>Click Here</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="enquire-section">
            <FloorPlanLayout title={"Complete Costing Details"} imageSrc={floorplanimg}/>
            </div>
            <BrochureDialog isOpen={isDialogOpen} closeDialog={closeDialog} />
        </div>
    );
};

export default PricingSection;
