import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import imgcarosel1 from '../images/img1.jpg';
// import imgcarose2 from '../images/img2.jpg';
// import imgcarose3 from '../images/img3.jpg';

import imgcarosel1 from '../images/img1.webp';
import imgcarose2 from '../images/img2.webp';
import imgcarosel5 from '../images/img5.webp';
import imgcarosel6 from '../images/img6.webp';
import imgcarosel7 from '../images/img7.webp';
import imgcarosel8 from '../images/img8.webp';
import './Gallery.css';

const images = [
  {
    original: imgcarosel1,
    thumbnail: "https://picsum.photos/id/1018/250/150/",
    description: "Beautiful Landscape 1",
  },
  {
    original: imgcarose2,
    thumbnail: "https://picsum.photos/id/1015/250/150/",
    description: "Stunning View 2",
  },
  {
    original: imgcarosel5,
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    description: "Serene Scene 3",
  },

  {
    original: imgcarosel6,
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    description: "Serene Scene 3",
  },
  {
    original: imgcarosel7,
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    description: "Serene Scene 3",
  },
  {
    original: imgcarosel8,
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    description: "Serene Scene 3",
  },
];

const MyGallery = () => {
  return (
    <ImageGallery
      items={images}
      showPlayButton={true}
      showFullscreenButton={true}
      showBullets={true}
      autoPlay={true}
      slideDuration={1000}
      slideInterval={5000}
      renderLeftNav={(onClick, disabled) => (
        <button
          type="button"
          className="image-gallery-custom-left-nav"
          disabled={disabled}
          onClick={onClick}
        >
          &#8249; {/* Left arrow symbol */}
        </button>
      )}
      renderRightNav={(onClick, disabled) => (
        <button
          type="button"
          className="image-gallery-custom-right-nav"
          disabled={disabled}
          onClick={onClick}
        >
          &#8250; {/* Right arrow symbol */}
        </button>
      )}
    />
  );
};

export default MyGallery;
