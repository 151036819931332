import React from 'react';
import './VirtualSiteTour.css';

const VirtualSiteTour = () => {
  return (
    <div className="virtual-site-tour">
     <div className="tour-title2">Virtual Site Tour</div>
      <div className="tour-video">
        <iframe
          src="https://www.youtube.com/embed/-sTb2vE-7eE?si=AVh3Wc2_ppinAR3t"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className="tour-title">Virtual Site Tour</div>
    </div>
  );
};

export default VirtualSiteTour;
