import React from 'react';
import './SiteVisitInfo.css'; // Add styles for site visit info

const SiteVisitInfo = () => {
  return (
    <div className="site-visit-info">
      <button className="book-visit-btn">Book A Site Visit</button>
      <ul>
        <li>✔️ Configurations : 2, 3, 4, 5 BHK</li>
        <li>✔️ Location : Palm Beach Road, Seawoods Nerul Navi Mumbai</li>
        <li>✔️ Price : 2.25 Cr.* Onwards</li>
      </ul>
    </div>
  );
};

export default SiteVisitInfo;
