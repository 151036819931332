import {
  faBars,
  faBuilding,
  faDollarSign,
  faDownload,
  faHome,
  faImages,
  faMap,
  faMapMarkedAlt,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import BrochureDialog from '../FormDialog/FormDialog';
import logo from '../images/gamilogo.png';
import './Header.css';


const Header = ({ onNavClick }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleNavClick = (section) => {
    onNavClick(section);
    closeMobileMenu();
  };

  const openDialog = (e) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target) && !event.target.closest('.hamburger')) {
      closeMobileMenu();
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <header className="header">
      <div className="header-left">
        <img src={logo} alt="Logo" className="header-logo-img" />
      </div>
      <div className="header-center">
        <nav ref={navRef} className={`header-nav ${isMobileMenuOpen ? 'open' : ''}`}>
          <a href="#home" className="header-nav-item" onClick={() => handleNavClick('Home')}>
            <FontAwesomeIcon icon={faHome} className="header-nav-icon" />
            Home
          </a>
          <a href="#price" className="header-nav-item" onClick={() => handleNavClick('Price')}>
            <FontAwesomeIcon icon={faDollarSign} className="header-nav-icon" />
            Price
          </a>
          <a href="#site-floor-plan" className="header-nav-item" onClick={() => handleNavClick('Site & Floor Plan')}>
            <FontAwesomeIcon icon={faMapMarkedAlt} className="header-nav-icon" />
            Site & Floor Plan
          </a>
          <a href="#amenities" className="header-nav-item" onClick={() => handleNavClick('Amenities')}>
            <FontAwesomeIcon icon={faBuilding} className="header-nav-icon" />
            Amenities
          </a>
          <a href="#gallery" className="header-nav-item" onClick={() => handleNavClick('Gallery')}>
            <FontAwesomeIcon icon={faImages} className="header-nav-icon" />
            Gallery
          </a>
          <a href="#location" className="header-nav-item" onClick={() => handleNavClick('Location')}>
            <FontAwesomeIcon icon={faMap} className="header-nav-icon" />
            Location
          </a>
          {!isMobileMenuOpen && (
            <a className="header-nav-item" onClick={openDialog}>
              <FontAwesomeIcon icon={faDownload} className="header-nav-icon brochure-icon" />
              Brochure
            </a>
          )}
        </nav>
      </div>
      <div className="header-right">
        <div className="header-icons">
          <button className="hamburger" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
          </button>
        </div>
        <img src={logo} alt="Logo" width={150} className="mobile-logo" />
      </div>
      <BrochureDialog isOpen={isDialogOpen} closeDialog={closeDialog} />
    </header>
  );
};

export default Header;