import { CButton, CCard, CCardBody, CCardImage, CCardTitle } from '@coreui/react';
import React, { useState } from 'react';
import BrochureDialog from '../FormDialog/FormDialog';
import './FloorPlanLayout.css'; // Import your custom CSS

function FloorPlanLayout({ title, imageSrc }) {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = (e) => {
    e.preventDefault();
    setIsDialogOpen(true); // Always set to true to ensure the dialog opens
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };


  return (
    <div className="floor-plan-container">
      <CCard className="floor-plan-card">
        <div className="card-overlay">
          <CButton className="enquire-button" color="primary" onClick={openDialog }>ENQUIRE NOW</CButton>
        </div>
        <CCardImage className="card-image" orientation="top" src={imageSrc} />
        <CCardBody className="card-body"  onClick={openDialog }>
          <CCardTitle className="card-title">{title}</CCardTitle>
        </CCardBody>
      </CCard>
      <BrochureDialog isOpen={isDialogOpen} closeDialog={closeDialog} />
    </div>
  );
}

export default FloorPlanLayout;
