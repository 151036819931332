import React from 'react'
import MainLayout from './MainLayout/MainLayout'

function App() {
  return (
    <div>
    <MainLayout/>
    </div>
  )
}



export default  App
